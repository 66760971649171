'use strict';

class QuickActionMenuAcceptScreen extends GUI.WelcomeScreenBase {
    constructor() {
        super($('<div></div>'));
    }

    getWelcomeScreenConfig() {
        return {
            title: _("loxone-control.quick-action"),
            subtitle: _("loxone-control.quick-action.welcome-screen.description"),
            icon: Icon.TRIOLOGY,
            acceptButton: _('loxone-control.quick-action.welcome-screen.accept-button'),
            declineButton: _('loxone-control.quick-action.welcome-screen.decline-button'),
            declineDisc: _('loxone-control.quick-action.welcome-screen.change-description')
        };
    }

    remove() {
        PersistenceComponent.setQuickActionMenuAcceptScreenViewed(true);
        super.remove(...arguments);
    }

    _acceptAction() {
        LoxoneControl.setVisibleInStatusBar(true);
        this.remove();
    }

    _declineAction() {
        this.remove();
    }

}

GUI.QuickActionMenuAcceptScreen = QuickActionMenuAcceptScreen;
